import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Post from "../components/post"
import { getSrc } from "gatsby-plugin-image"

const BlogPostTemplate = ({ data, location, pageContext }) => {
  const post = data.mdx
  const { related } = pageContext
  const image = post.frontmatter.metaImage ? post.frontmatter.metaImage : null

  return (
    <Layout location={location}>
      <Seo
        title={post.frontmatter.title}
        description={post.excerpt}
        slug={post.fields.slug}
        image={getSrc(image)}
        frontmatter={post.frontmatter}
      />
      <Post post={post} url={location.href} related_posts={related} />
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($id: String!) {
    mdx(id: { eq: $id }) {
      id
      excerpt(truncate: true, pruneLength: 160)
      body
      headings {
        depth
        value
      }
      frontmatter {
        date1(locale: "ja")
        date2(locale: "ja")
        static
        tags
        title
        metaImage: topImage {
          childImageSharp {
            gatsbyImageData(
              layout: FIXED
              width: 1200
              height: 600
              transformOptions: { cropFocus: CENTER }
            )
          }
        }
      }
      fields {
        slug
      }
    }
  }
`
