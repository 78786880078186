import * as React from "react"
import { css } from "@emotion/react"
import { GoldenRatio } from "../../utils/golden_ratio"
import { Link } from "gatsby"
import { usePostData } from "../data"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { CssMobile, CssNotMobile } from "../../utils/responsive"
import Home from "../../../images/svg/home.inline.svg"

const common_css = `
        box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
          rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
        margin: 0 auto;
        max-width: 580px;
        margin-bottom: ${GoldenRatio(1)}rem;
        padding: ${GoldenRatio(-1)}rem;
        width: 98%;
`

export const LinkCard = ({ url, title }) => {
  if (url.length === 4) {
    return <InnerLink url={url} />
  } else {
    return <OuterLink url={url} title={title} />
  }
}

const OuterLink = ({ url, title }) => {
  let striped_url = url.replace("https://", "")
  striped_url = striped_url.replace("http://", "")
  const strip_num = striped_url.indexOf("/", 0)
  let url_basename
  if (strip_num === -1) {
    url_basename = striped_url
  } else {
    url_basename = striped_url.slice(0, strip_num)
  }

  return (
    // <Sphere
    //   css={css`
    //     fill: var(--color-text);
    //     height: 90px;
    //     width: 90px;
    //     vertical-align: middle;
    //   `}
    // />
    <section
      css={css`
        ${common_css};
      `}
    >
      <div
        css={css`
          text-align: left;
          background-color: var(--color-comment-background);
          font-size: var(--fontSize-0);
          padding: ${GoldenRatio(-2)}rem;

          #linkcard-title {
            font-size: var(--fontSize-1);
            font-weight: bold;
          }
          #linkcard-cf {
            margin-right: 0.66rem;
            color: var(--color-text-light);
            font-weight: bold;
            border: 1px solid var(--color-text-light);
            padding: 3px;
          }
        `}
      >
        <span id="linkcard-cf">参考</span>
        <a href={url}>
          <span id="linkcard-title">{title}</span>
        </a>
      </div>
      <LcFooter url_basename={url_basename} />
    </section>
  )
}

const InnerLink = ({ url }) => {
  const nodes = usePostData()
  const post = nodes.filter(x => x.fields.slug === `/${url}/`)[0]
  const image = getImage(post.frontmatter.topImage2)
  const title = post.frontmatter.title
  const slug = post.fields.slug
  const url_basename = "kanau.page"

  return (
    <section
      css={css`
        ${common_css};

        ${CssNotMobile(css`
          :hover {
            .eyecatch {
              filter: brightness(1.2);
            }
          }
        `)}
      `}
    >
      <div
        css={css`
          display: flex;
          background-color: var(--color-comment-background);
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            margin-right: 1rem;
            ${CssMobile(
              css`
                margin-right: 0.66rem;
              `
            )};

            text-align: left;
            color: var(--color-text);
            font-size: var(--fontSize-0);
            padding: ${GoldenRatio(-1)}rem;

            #linkcard-title {
              font-size: var(--fontSize-1);
              font-weight: bold;
            }
            #linkcard-excerpt {
              margin-top: 1rem;
              margin-right: 1rem;
              ${CssMobile(
                css`
                  display: none;
                `
              )}
            }
          `}
        >
          <div>
            <Link to={slug}>
              <span id="linkcard-title">{title}</span>
            </Link>
          </div>
          <div id="linkcard-excerpt">{post.excerpt}</div>
        </div>
        <div>
          <Link
            to={slug}
            css={css`
              float: right;
              width: 120px;
              background-color: #fff;
              padding-left: 0.6rem;
              padding-bottom: 0.6rem;
              ${CssMobile(
                css`
                  width: 80px;
                `
              )}
            `}
          >
            <GatsbyImage
              image={getImage(image)}
              alt="eyecatch"
              className="eyecatch"
            />
          </Link>
        </div>
      </div>
      <LcFooter url_basename={url_basename} />
    </section>
  )
}

const LcFooter = ({ url_basename }) => (
  <div>
    <Home
      css={css`
        fill: var(--color-text-light);
        width: 16px;
        height: 16px;
        vertical-align: -2px;
      `}
    />
    {` `}
    {url_basename}
  </div>
)
