import { graphql, useStaticQuery } from "gatsby"

export const usePostData = () => {
  const data = useStaticQuery(graphql`
    query NonPageQuery {
      allMdx(filter: { frontmatter: { static: { ne: true } } }) {
        nodes {
          id
          excerpt(truncate: true, pruneLength: 90)
          frontmatter {
            title
            tags
            topImage {
              childImageSharp {
                gatsbyImageData(
                  aspectRatio: 1.9
                  transformOptions: { cropFocus: CENTER }
                )
              }
            }
            topImage2: topImage {
              childImageSharp {
                gatsbyImageData(
                  aspectRatio: 1
                  transformOptions: { cropFocus: CENTER }
                  width: 120
                )
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
  `)

  return data.allMdx.nodes
}
