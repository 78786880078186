import * as React from "react"
import { css } from "@emotion/react"
import { TagsNavi } from "../tags"
import { Datetime } from "../utils/datetime"
import MarkdownWrapper from "./markdown"
import loadable from "@loadable/component"
import {
  CssBetweenTabletMiddleAndMobile,
  CssMobile,
  CssOverTabletMiddle,
  CssUnderTabletMiddle,
  ReactMobile,
  ReactNotMobile,
  ReactOverTabletMiddle,
  ReactUnderTabletMiddle,
} from "../utils/responsive"
import {
  content_frame_desktop,
  content_frame_tablet,
  frame_common,
  frame_mobile,
  grid_desktop,
  grid_tablet,
  right_panel,
} from "../utils/css"
const RelatedPosts = loadable(() => import("./related_posts"))
const Sns = loadable(() => import("./sns"))
const Ranking = loadable(() => import("../ranking"))
const ScrollSyncToc = loadable(() => import("./toc/scroll_sync_toc"))
const ModalToc = loadable(() => import("./toc/modal_toc"))

const PostFrame = ({
  children,
  title,
  url,
  tags,
  post_static,
  post_headings,
}) => {
  return (
    <main
      css={css`
        ${frame_common};
        ${CssOverTabletMiddle(grid_desktop, content_frame_desktop)};
        ${CssBetweenTabletMiddleAndMobile(grid_tablet, content_frame_tablet)};
      `}
    >
      <div
        className="sns_panel"
        css={css`
          grid-column: 1/2;
          position: sticky;
          top: 10rem;
          height: 12rem;
          margin-bottom: 5rem;
          ${CssUnderTabletMiddle(css`
            grid-column: auto;
            position: relative;
            margin: 0;
            padding: 0;
            height: 0;
          `)}
        `}
      >
        <ReactOverTabletMiddle>
          {!post_static && <Sns title={title} url={url} />}
        </ReactOverTabletMiddle>
      </div>

      <div
        className="article_panel"
        css={css`
          grid-column: 2/3;
          background-color: #fff;
        `}
      >
        {children}
      </div>

      <ReactNotMobile>
        <div
          className="side_panel"
          css={css`
            grid-column: 3/4;
            ${right_panel};
          `}
        >
          {!post_static && (
            <React.Fragment>
              <Ranking tag={tags} />
              <ScrollSyncToc headings={post_headings} />
            </React.Fragment>
          )}
        </div>
      </ReactNotMobile>
    </main>
  )
}

class Post extends React.Component {
  render() {
    const { post, url, related_posts } = this.props
    return (
      <React.Fragment>
        <div
          css={css`
            posision: relative;
            ${frame_mobile};
          `}
          id="post_wrapper"
        >
          <PostFrame
            title={post.frontmatter.title}
            url={url}
            tags={post.frontmatter.tags}
            post_static={post.frontmatter.static}
            post_headings={post.headings}
          >
            <article
              className="blog-post"
              itemScope
              itemType="http://schema.org/Article"
              css={css`
                padding: var(--post-padding);
                ${CssMobile(css`
                  padding: var(--post-padding-mobile);
                `)}
              `}
            >
              <div
                css={css`
                  padding: 15% 10%;
                  margin-bottom: 5rem;
                  border: 3px double var(--color-text);
                  color: var(--color-text-light);
                  h1 {
                    color: var(--color-primary);
                    font-size: var(--fontSize-4);
                  }
                  ${CssMobile(css`
                    h1 {
                      font-size: var(--fontSize-3);
                    }
                  `)};
                `}
              >
                <h1 itemProp="headline">{post.frontmatter.title}</h1>
                <Datetime post={post} raw={false} />
                <TagsNavi tags={post.frontmatter.tags} />
              </div>
              <MarkdownWrapper
                html={post.body}
                post_static={post.frontmatter.static}
              />
            </article>
            <ReactUnderTabletMiddle>
              {!post.frontmatter.static && (
                <Sns title={post.frontmatter.title} url={url} />
              )}
            </ReactUnderTabletMiddle>
          </PostFrame>
          {!post.frontmatter.static && (
            <RelatedPosts related_posts={related_posts} />
          )}
          <ReactMobile>
            <div
              css={css`
                margin-bottom: 5rem;
              `}
            >
              {!post.frontmatter.static && (
                <Ranking tag={post.frontmatter.tags} />
              )}
            </div>
            <ModalToc headings={post.headings} />
          </ReactMobile>
        </div>
      </React.Fragment>
    )
  }
}
export default Post
