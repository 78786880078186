import React, { useEffect } from "react"

const AD_SLOT = [
  "9206190112",
  "3548994166",
  "5627250797",
  "9873040573",
  "4061476548",
  "7246877233",
  "2748394879",
  "4667502275",
  "9352852747",
  "4620713898",
]

export const AdsenseArticle = ({ index }) => {
  useEffect(() => {
    if (window.adsbygoogle && process.env.NODE_ENV !== "development") {
      window.adsbygoogle.push({})
    }
  }, [])

  return (
    <ins
      className="adsbygoogle"
      style={{ display: "block", textAlign: "center" }}
      data-ad-layout="in-article"
      data-ad-format="fluid"
      data-ad-client="ca-pub-9919529023699620"
      data-ad-slot={AD_SLOT[index % 10]}
    ></ins>
  )
}

export const AdsenseDisplay = () => {
  useEffect(() => {
    if (window.adsbygoogle && process.env.NODE_ENV !== "development") {
      window.adsbygoogle.push({})
    }
  }, [])

  return (
    <ins
      className="adsbygoogle"
      style={{
        display: "block",
      }}
      data-ad-client="ca-pub-9919529023699620"
      data-ad-slot="6540058351"
      data-ad-format="auto"
      data-full-width-responsive="true"
    ></ins>
  )
}
