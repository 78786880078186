import React, { Fragment } from "react"
import { css } from "@emotion/react"
import { GoldenRatio } from "../utils/golden_ratio"
import { AdsenseArticle } from "./adsense"
import { CssMobile } from "../utils/responsive"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { MDXProvider } from "@mdx-js/react"
import { Point, Voice } from "./shortcodes/point"
import { LinkCard } from "./shortcodes/linkcard"

const shortcodes = { Point, LinkCard, Voice }

const striptags = require("striptags")

class MarkdownWrapper extends React.PureComponent {
  componentDidMount() {
    const images = document.getElementsByClassName("gatsby-resp-image-image")
    const imageArray = Array.from(images)
    imageArray.forEach(element => {
      element.removeAttribute("title")
    })
  }

  render() {
    const post_static = this.props.post_static
    const html = this.props.html
    const string_cut_length = 3000
    let pos = 0
    let pos_inserted = 0
    let string_length = 0
    const markdown_data = []
    while (pos !== -1) {
      const h2 = html.indexOf("<h2", pos + 1)

      // 文頭からh2まで
      if (pos === 0) {
        const string = html.slice(pos_inserted, h2)
        markdown_data.push(string)
        pos = h2
        pos_inserted = h2
        continue
      }

      // h2とh3を比較し、new_posを決める
      // h2もh3も見当たらなければ終了
      const h3 = html.indexOf("<h3", pos + 1)
      let new_pos = h2 < h3 ? h2 : h3
      if (new_pos === -1) {
        if (h2 === -1 && h3 === -1) {
          // 終了
          markdown_data.push(html.slice(pos_inserted))
          break
        } else {
          if (h2 !== -1) {
            new_pos = h2
          } else {
            new_pos = h3
          }
        }
      }
      const string = html.slice(pos_inserted, new_pos)
      string_length += striptags(string).length
      if (string_length > string_cut_length) {
        markdown_data.push(html.slice(pos_inserted, new_pos))
        string_length = 0
        pos_inserted = new_pos
      }
      pos = new_pos
    }
    return (
      <Fragment>
        {markdown_data.map((e, i) => (
          <Fragment key={i}>
            {i !== 0 && <AdsenseArticle index={i - 1} />}
            <MarkdownMain html={e} />
          </Fragment>
        ))}
        {!post_static && (
          <p
            className="lastphrase"
            css={css`
              text-align: right;
              margin-top: 4rem;
              margin-bottom: 0;
            `}
          >
            以上、今日はこれにて！
          </p>
        )}
      </Fragment>
    )
  }
}
export default MarkdownWrapper

const MarkdownMain = ({ html }) => {
  return (
    <section
      css={css`
        p {
          margin-bottom: 1.61803rem;
          line-height: 1.61803rem;
        }
        h2 {
          color: #fff !important;
          background-color: var(--color-primary) !important;
          padding: calc(var(--post-padding) * ${GoldenRatio(-2)})
            calc(var(--post-padding) * ${GoldenRatio(0)});
          margin: calc(var(--post-padding) * ${GoldenRatio(-1)})
            calc(var(--post-padding) * -1);
          ${CssMobile(css`
            margin: calc(var(--post-padding-mobile) * ${GoldenRatio(-1)})
              calc(var(--post-padding-mobile) * -1);
          `)}

          ${CssMobile(css`
            font-size: var(--fontSize-3);
          `)};
        }
        h3 {
          padding-left: 0.5rem;
          margin-left: calc(var(--post-padding) * -0.33);
          margin-right: calc(var(--post-padding) * -0.33);
          border-bottom: 2px solid var(--color-primary);
          ${CssMobile(css`
            font-size: var(--fontSize-2);
          `)};
        }
        h4 {
          position: relative;
          display: inline;
          ${CssMobile(css`
            font-size: var(--fontSize-1);
          `)};
          ::before {
            color: var(--color-primary);
            content: "■";
            font-size: var(--fontSize-0);
            vertical-align: middle;
            margin-right: ${GoldenRatio(-1)}rem;
          }
        }

        ul,
        ol {
          padding: ${GoldenRatio(0)}rem ${GoldenRatio(2)}rem;
          margin-top: ${GoldenRatio(1)}rem;
          margin-bottom: ${GoldenRatio(0)}rem;
          background-color: var(--color-article-sub);
        }
        li {
          padding-left: ${GoldenRatio(-2)}rem;
        }
        li > ul {
          margin: 0;
          padding: 0;
          padding-left: ${GoldenRatio(-2)}rem;
        }
        pre {
          margin-top: ${GoldenRatio(1)}rem;
          margin-bottom: ${GoldenRatio(-2)}rem;
        }
        pre[class*="language-"] > code {
          white-space: pre-wrap;
          word-wrap: break-word;
        }
        strong {
          color: var(--color-article-strong);
        }
        .gatsby-resp-image-figure {
          > figcaption {
            text-align: right;
            margin-top: ${GoldenRatio(-3)}rem;
            opacity: 66%;
            font-size: var(--fontSize-0);
            ::before {
              content: "Photo by ";
              vertical-align: baseline;
            }
          }
          .gatsby-resp-image-wrapper {
            box-shadow: 0 0 5px gray;
          }
        }
      `}
    >
      <MDXProvider components={shortcodes}>
        <MDXRenderer>{html}</MDXRenderer>
      </MDXProvider>
    </section>
  )
}
