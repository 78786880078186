import * as React from "react"
import { css } from "@emotion/react"
import { GoldenRatio } from "../../utils/golden_ratio"
import { CssMobile } from "../../utils/responsive"

const common_css = `
  margin-top: ${GoldenRatio(2)}rem;
  margin-bottom: ${GoldenRatio(1)}rem;
  background-color: var(--color-comment-background);
  padding: 1rem;
  position: relative;
`
const voice_css = `
  content: "";
  position: absolute;
  background-color: var(--color-comment-background);
  border-radius: 50%;
`

export const Point = ({ children }) => {
  return (
    <div
      css={css`
        ${common_css};
        ::before {
          color: var(--color-comment);
          content: "Point!!";
          position: absolute;
          top: -${GoldenRatio(-1)}rem;
          left: -${GoldenRatio(-1)}rem;
          font-weight: bold;
        }
      `}
    >
      {children}
    </div>
  )
}

export const Voice = ({ children }) => {
  return (
    <div
      css={css`
        ${common_css};
        width: 66%;
        margin-right: 0;
        margin-left: auto;
        border-radius: 15px;
        margin-bottom: ${GoldenRatio(3)}rem;

        ::after {
          right: -18px;
          bottom: -12px;
          width: 20px;
          height: 18px;
          ${voice_css}
        }
        ::before {
          right: -35px;
          bottom: -20px;
          width: 11px;
          height: 10px;
          ${voice_css}
        }
        ${CssMobile(css`
          width: 75%;
          ::after {
            right: -10px;
            bottom: -15px;
            width: 18px;
            height: 16px;
          }
          ::before {
            right: -21px;
            bottom: -22px;
            width: 9px;
            height: 8px;
          }
        `)}
      `}
    >
      {children}
    </div>
  )
}
